<template>
  <v-container fluid>
    <UserAuthForm
      v-if="user"
      ref="userAuthForm"
      type="Register"
      v-model="user"
      @submitForm="createUser"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  components: {
    UserAuthForm,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    createUser(user) {
      this.$store.dispatch('misc/setLoading', {
        status: true,
        text: 'Registering new User. Please wait...',
      });
      this.$store
        .dispatch('registerUser', user)
        .then(response => {
          this.$store.dispatch('misc/setSnack', {
            text: response.data.msg,
            color: 'success',
          });
          this.reset();
          this.$router.push({ name: 'Login' });
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        })
        .finally(() => {
          this.$store.dispatch('misc/setLoading', false);
        });
    },
    reset() {
      this.$refs.userAuthForm.reset;
      this.user = this.emptyUser();
    },
  },
  computed: {
    ...mapGetters(['emptyUser']),
  },
  mounted() {
    this.user = this.emptyUser();
  },
};
</script>

<style></style>
